@import "styles/config";
$accentLight: #eae0d7;
$accentLightAlt: #eae0d7;
$accentDark: #2d5e6c;
$baseWhite: #ffffff;
$box-shadow: 0px 0px 20px $accentLightAlt;
$background-gray: #f4f4f4;
$baseLink: #d36855;

$background: #eae0d7 !default;

%location {
  .location {
    &-container {
      background-color: $accentLightAlt !important;
      &--header {
        background-color: $accentLight;

        & .body {
          padding: 2rem 1rem;
          background-color: $accentLight;

          &-art {
            height: 3.75rem !important;
            right: 2rem !important;
          }
        }

        & .tabs {
          background-color: $accentLight;

          &-container {
            padding: 1rem 2rem 0 2rem;
          }
        }
      }
      &--cards {
        background-color: $accentLightAlt !important;
      }
    }
  }
}

%card {
  box-shadow: none !important;
  background-color: $accentLight !important;
}

%header {
  border-bottom: 3px solid $accentDark;
  .header {
    &-wrapper {
      &--left {
        & .logo {
          &:not(:last-child) {
            border-right: none !important;
          }
        }
      }
    }
  }
}

%locationCard {
  @include elevation(2);
  border-bottom: 3px solid $accentDark;
  border-top: 3px solid $accentDark;
  flex-shrink: 0;

  .card {
    & > div:first-child {
      margin-bottom: 0 !important;
    }
    padding-bottom: 0.25rem !important;

    & > div:nth-of-type(2) {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  }

  .address {
    & span:nth-of-type(3) {
      display: none !important;
    }

    & > *:first-child > span {
      margin-bottom: 0.25rem !important;
    }
  }
}

.rc-tabs-bar {
  background-color: $accentLight;
  padding: 1rem;
  border-bottom: 3px solid $accentDark !important;
}

.rc-tabs-tab {
  text-align: center;
  margin: 0 3.75rem;
  right: 2.813rem;

  &-active {
    color: transparent;
  }
}

%nav {
  box-shadow: none !important;
  border-bottom: 2.5px solid $accentDark !important;
}

%order {
  .order {
    &-header {
      border-bottom: 2.5px solid #2d5e6c !important;
    }
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

%orderItem {
  .orderItem {
    &-actions {
      padding-top: 0.3125rem !important;
      border-top: 2px solid $accentDark !important;
      &-btn {
        &:first-child:not(:only-child) {
          border-right: 2px solid $accentDark !important;
          border-radius: 0 !important;
        }
      }
    }
  }
}

%menuItem {
  @include elevation(1, 2, "hover");
  transition: all 0.5s ease-in-out;
}

// special styling for navbar in itemDetails
%itemDetails-tabs {
  .itemDetails-tabs {
    &-menu-options {
      padding: 0px 2rem !important;
    }
    &-nav-container {
      &[data-fixed="true"] ul li:first-child {
        padding-left: 2rem !important;
      }
      .nav {
        background-color: $accentLight !important;
        border-bottom: 2px solid $accentDark !important;

        .nav-item {
          .item-button {
            padding: 0.5rem 1rem;
          }

          &[data-active="1"] {
            .item-button {
              background-color: $accentDark !important;

              span {
                color: white !important;
              }
            }
          }
        }
      }
    }
  }
}

%itemDetails {
  .itemDetails-back {
    box-shadow: none !important;
    & img {
      width: 2rem;
      height: 2rem;
      position: relative;
      right: 0.5rem;
    }
  }
}

%cartContainer {
  div:nth-of-type(2) {
    box-shadow: none !important;
  }
}

.price-container {
  & > div:nth-of-type(2) {
    button {
      color: $baseLink !important;
    }
  }
}

%tipsContainer {
  .tipsContainer {
    &-wrapper {
      &--singleTip {
        border-color: $accentDark !important;
      }
      &--customAmountSelected {
        color: white !important;
      }
    }
  }
}

/// sets a max width to the header info component.
%locationInfo {
  max-width: 30rem !important;

  .locationInfo-body {
    line-height: 1.5;
  }
}

%cart {
  .cart {
    &-content {
      background: none !important;
    }
  }
}

%fieldsContainer {
  background-color: $accentLight !important;
}

%loyaltyHeader {
  padding: 0 0 1rem 0 !important;

  .loyaltyHeader {
    &-description {
      span:first-child {
        font-size: 2rem !important;
      }
      &-text {
        text-align: left;
        margin-left: 1rem;
        max-width: 20rem;
      }
    }
  }
}

%quantitySelector {
  .quantitySelector {
    &-container {
      .quantity-btn {
        & span {
          display: contents !important;
        }
      }
    }
  }
}

%upsellItem {
  .upsellItem {
    &-cart-btn {
      justify-content: flex-start;
      span:first-child {
        text-transform: uppercase !important;
      }
    }
  }
}

.payment-form {
  background-color: $accentLight;
}

%modifierItem {
  border-bottom-width: 1.5px !important;
  border-bottom-color: $accentDark;
  .modifierItem {
    &-container {
      &--right {
        & .selectors {
          &-quantityBtn {
            position: relative !important;
            background-color: $accentDark !important;
            color: white !important;
            border: none !important;
            &--minus:before {
              top: -25%;
              position: absolute !important;
            }
            &--plus:before {
              top: -13%;
              position: absolute !important;
            }
          }
        }
      }
    }
  }
}

%itemDetailsHeader {
  .itemDetailsHeader {
    &-content {
      margin: 0 2rem !important;
    }
  }
}

%complete {
  .complete {
    &-content-bar {
      border-radius: 1rem;
    }
  }
}

%nestedModifier {
  .nestedModifier {
    &-content {
      padding: 0 2rem !important;
    }
  }
}

%fieldItem {
  .fieldItem {
    input::placeholder {
      color: rgba(45, 94, 108, 0.5) !important;
    }
    input:focus {
      input::placeholder {
        color: $accentDark !important;
      }
    }
  }
}

%empty {
  .message {
    span:first-child {
      font-size: 2rem !important;
    }
  }
}

.select {
  font-family: "PPPangramSans-Bold" !important;
}

%scheduleDate {
  .scheduleDate {
    &-label {
      span:first-child {
        color: $accentDark !important;
      }
    }
  }
}

%bar-container {
  background-color: #fff !important;
}

.loyaltyRewardSection {
  border-bottom: none !important;
}

%profile {
  background-color: $accentLightAlt !important;
}

%loyalty {
  & .container {
    .loyalty-progress-section {
      border-bottom: none !important;
    }
  }
}

.loyalty-progress-section {
  border-bottom: none !important;
}

@media screen and (max-width: 24em) {
  .rc-tabs-top .rc-tabs-bar {
    span {
      font-size: 1.25rem !important;
    }
  }

  %locationInfo {
    width: 90% !important;
  }
}
